import { Card, CardContent } from '@/components/ui/card';
import RewardCardHeader from '../others/RewardCardHeader';
import RewardMethodSelect from '../input/RewardMethodSelect';
import { useMemo } from 'react';
import { CampaignRewardType, ICampaignAddReward } from '@/features/campaigns/types';
import InputText from '@/components/element/inputs/InputText';
import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import { Checkbox } from '@/components/ui/checkbox';
import { TrackingEvents } from '@/types/tracking.type';

const CustomReward = ({
	whitelistReward,
	setWhitelistReward,
	errors,
	noEndTime,
}: {
	whitelistReward: ICampaignAddReward;
	setWhitelistReward: (reward: ICampaignAddReward) => void;
	noEndTime: boolean;
	errors: {
		name: boolean;
		image: boolean;
		winners: boolean;
		method: boolean | string;
	};
}) => {
	const allowedMethods = useMemo(() => {
		const methods = [CampaignRewardType.Fcfs, CampaignRewardType.Unlimited];
		if (!noEndTime) {
			methods.push(CampaignRewardType.LuckyDraw);
			methods.push(CampaignRewardType.Leaderboard);
		}
		return methods;
	}, []);
	return (
		<Card className="mb-10 mt-10 shadow-none">
			<RewardCardHeader
				title="Custom Rewards"
				description="Custom are fungible tokens that are compatible with the Ethereum network."
				tooltip="Make sure to give users crystal clear instructions for the claim and distribution of rewards."
				trackingEvent={TrackingEvents.CustomRewardsProTipViewed}
			/>
			<CardContent>
				<div className="grid grid-cols-9 gap-10">
					<div className="space-y-7 col-span-5">
						<div>
							<div className="text-sm font-medium mb-1">
								Reward Method
							</div>
							<RewardMethodSelect
								allowedMethods={allowedMethods}
								value={whitelistReward?.method}
								setValue={(value) =>
									setWhitelistReward({
										...whitelistReward,
										method: value as CampaignRewardType,
									})
								}
								errorText={
									errors?.method || 'Please select a reward method'
								}
								error={!!errors?.method}
							/>
						</div>
						<InputText
							label="Reward Name"
							placeholder="Intract Whitelist to early holders"
							setValue={(e) => {
								setWhitelistReward({
									...whitelistReward,
									whitelistReward: {
										...whitelistReward.whitelistReward,
										name: e,
									},
								});
							}}
							value={whitelistReward?.whitelistReward?.name}
							error={errors?.name}
							errorText="Please enter a reward name"
						/>
						{whitelistReward?.method ===
						CampaignRewardType.Leaderboard ? (
							<div className="mb-8 flex items-center">
								<Checkbox
									checked={whitelistReward?.autoRewardDistribution}
									onCheckedChange={(check) =>
										setWhitelistReward({
											...whitelistReward,
											autoRewardDistribution: check as boolean,
										})
									}
									className="me-2"
								/>
								<div className="text-sm ">
									Allow auto distribution of rewards?
								</div>
							</div>
						) : null}
						{whitelistReward?.method !==
							CampaignRewardType.Unlimited && (
							<InputText
								label="Total Winners"
								placeholder="100"
								type="number"
								setValue={(e) => {
									setWhitelistReward({
										...whitelistReward,
										numRewards: parseInt(e?.toString()),
									});
								}}
								value={whitelistReward?.numRewards}
								error={errors?.winners}
								errorText="Please enter a number of winners"
							/>
						)}
					</div>
					<div className="col-span-4">
						<DropzoneWithReposition
							file={whitelistReward.whitelistReward?.nftImage}
							fileLink={whitelistReward.whitelistReward?.image}
							clearSelection={() => {
								setWhitelistReward({
									...whitelistReward,
									whitelistReward: {
										...whitelistReward.whitelistReward,
										nftImage: null,
										image: '',
									},
								});
							}}
							setFile={(file: File | File[], fileLink: string) => {
								setWhitelistReward({
									...whitelistReward,
									whitelistReward: {
										...whitelistReward.whitelistReward,
										nftImage: file,
										image: fileLink,
									},
								});
							}}
							description="Recommended size: 400x400px"
							label="Reward Image"
							cta="Upload Image"
							aspectRatio={1 / 1}
							error={errors?.image}
							errorText="Please upload a valid image or video file."
							bannerPosition={
								whitelistReward.whitelistReward?.imagePosition
							}
							onImageReposition={({ x, y }) => {
								setWhitelistReward({
									...whitelistReward,
									whitelistReward: {
										...whitelistReward.whitelistReward,
										imagePosition: { x, y },
									},
								});
							}}
							allowedFiles={['images']}
							maxSize={3}
						/>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default CustomReward;
