import { ResponsiveChoropleth } from '@nivo/geo';
import { scaleQuantize } from 'd3-scale';
import worldMap from '@/assets/worldmap.json';
import { max } from 'd3-array';

interface GeoChartProps {
	data?: any[];
	height?: string;
	completedUsersCount?: number;
}

const GeoChart = ({ height, data, completedUsersCount }: GeoChartProps) => {
	const colorRange = [
		'rgba(189, 177, 222, 1)', // Least value
		'rgba(119, 99, 175, 1)',
		'rgba(99, 77, 163, 1)',
		'rgba(80, 54, 151, 1)',
		'rgba(60, 32, 140, 1)', // Greatest value
		'rgba(241, 71, 79, 1)',
	];

	const restrictedColor = 'rgba(241, 71, 79, 1)'; // Special color for restricted countries
	const unknownColor = '#CCCCCC'; // Color for countries not in the data

	const domainUpperLimit = max([completedUsersCount, 1000]);

	// Create a scale function to map values to colors
	const minCount = data ? Math.min(...data.map((d) => d.value)) : 0;

	const colorScale = scaleQuantize<string>()
		.domain([minCount, domainUpperLimit])
		.range(colorRange);

	// Map the data
	const mappedData = data?.map((item) => ({
		id: item.id,
		value: item.isRestricted ? Infinity : item.value, // Assign a special value for restricted countries
	}));

	// Adjust legend data creation
	const legendData = [
		{ id: 'restricted', label: 'Restricted', color: restrictedColor },
		{ id: 'unknown', label: 'No data', color: unknownColor },
		...colorRange.slice(0, -1).map((color, index) => {
			const thresholds = [
				minCount,
				domainUpperLimit / 5,
				(2 * domainUpperLimit) / 5,
				(3 * domainUpperLimit) / 5,
				(4 * domainUpperLimit) / 5,
			];
			const threshold = Math.round(thresholds[index]);
			const nextThreshold = Math.round(thresholds[index + 1]);

			let label;
			if (index === colorRange.length - 2) {
				label = `${threshold}+`;
			} else {
				label = `${threshold} - ${nextThreshold - 1}`;
			}

			return {
				id: `${threshold}`,
				label: label,
				color: color,
			};
		}),
	];

	return (
		<div style={{ height: height ?? '500px', width: '100%' }}>
			<ResponsiveChoropleth
				data={mappedData}
				features={worldMap.features}
				margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
				domain={[minCount, domainUpperLimit + 1]} // Adjust the domain to include the special value
				colors={colorScale} // Use the quantize scale for color mapping
				unknownColor="#CCCCCC"
				label="properties.name"
				valueFormat={(value) => {
					if (value === Infinity) return 'Restricted';
					if (value === 0) return 'No data';
					return value?.toLocaleString();
				}}
				projectionTranslation={[0.5, 0.5]}
				projectionRotation={[0, 0, 0]}
				enableGraticule={true}
				graticuleLineColor="#dddddd"
				borderWidth={0.5}
				borderColor="#152538"
				legends={[
					{
						anchor: 'bottom-left',
						direction: 'column',
						justify: true,
						translateX: 20,
						translateY: -25,
						itemsSpacing: 0,
						itemWidth: 94,
						itemHeight: 18,
						itemDirection: 'left-to-right',
						itemTextColor: '#444444',
						itemOpacity: 0.85,
						symbolSize: 18,
						data: legendData,
					},
				]}
			/>
		</div>
	);
};

export default GeoChart;
