import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogDescription,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import AnimateHeight from 'react-animate-height';
import InputSelect2 from '@/components/element/inputs/InputSelect2';
import { useGetAdminCampaigns } from '@/features/admin/hooks/useGetAdminCampaigns';
import { Status } from '@/features/campaigns/types';
import useBannerCreate, {
	IBannerCreate,
} from '@/features/admin/hooks/admin-cms-banners/useBannerCreate';
import { QUEST_URL } from '@/config';
import { cn } from '@/lib/utils';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { HomepageCarouselSourceEnum } from '@/features/admin/types/cms.types';

const ChooseBannerCreateMethod = ({
	open,
	setOpen,
	showChooseQuest,
	setShowChooseQuest,
	setOpenCreateBanner,
	setFormFields,
	formFields,
	questOptions,
}: {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	showChooseQuest: boolean;
	setShowChooseQuest: Dispatch<SetStateAction<boolean>>;
	setOpenCreateBanner: Dispatch<SetStateAction<boolean>>;
	setFormFields: Dispatch<SetStateAction<any>>;
	formFields: IBannerCreate;
	questOptions: any;
}) => {
	const {
		results: campaigns,
		search,
		setSearch,
		filters,
		setFilters,
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading,
		refetch,
		count,
	} = useGetAdminCampaigns();

	useEffect(() => {
		if (open) {
			setFilters((prev) => ({
				...prev,
				campaignStatus: [Status.Active],
				publishStatus: true,
			}));
			setPagination((prev) => ({
				...prev,
				pageSize: 30,
			}));
		}
		//this is done to reset the campaignId and link when the dialog is closed
		setFormFields((prev) => ({
			...prev,
			campaignId: '',
		}));
	}, [open]);

	const selectedCampaign = useMemo(() => {
		return campaigns?.find((i) => i._id === formFields?.campaignId);
	}, [formFields?.campaignId, campaigns]);

	const prepareForm = () => {
		setFormFields((prev) => ({
			...prev,
			enterpriseId: selectedCampaign?.enterpriseId,
			title: selectedCampaign?.name,
			description: selectedCampaign?.description,
			link: `${QUEST_URL}/quest/${selectedCampaign?._id}`,
			banner: selectedCampaign?.banner,
			index: 0, //start of list
			startDate: Date.now(),
			endDate: selectedCampaign?.endDate,
		}));
		setOpenCreateBanner(true);
		setOpen(false);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[570px] px-0 pb-0 overflow-visible">
				<DialogHeader className="border-b pb-3 px-5">
					<DialogTitle>Create a new banner</DialogTitle>
					<DialogDescription>
						Configure the banner from a quest or start with a blank
						canvas.
					</DialogDescription>
				</DialogHeader>
				<div className="px-5 mt-5 mb-4 space-y-8">
					<div className="flex justify-between gap-4  items-center">
						<div
							className={cn(
								'p-5 border rounded-lg text-base font-medium text-center cursor-pointer hover:shadow-md',
								showChooseQuest ? 'shadow-xl' : '',
							)}
							onClick={() => {
								setShowChooseQuest(true);
							}}
						>
							Create a banner from quest
						</div>
						<div
							className="p-5 border rounded-lg text-base font-medium text-center cursor-pointer hover:shadow-md"
							onClick={() => {
								setShowChooseQuest(false);
								setOpenCreateBanner(true);
								setOpen(false);
							}}
						>
							Create a banner from scratch
						</div>
					</div>
					<AnimateHeight
						duration={500}
						height={showChooseQuest ? 'auto' : 0}
					>
						<InputSelect2
							label="Select a quest"
							options={campaigns?.map((i) => ({
								label: i.name,
								value: i._id,
								searchText: i.name,
							}))}
							placeholder="Choose a campaign from the below list"
							value={formFields?.campaignId}
							setValue={(e) => {
								setFormFields((prev) => ({
									...prev,
									campaignId: e,
									source: HomepageCarouselSourceEnum.Quest,
									sourceId: e,
									link: `${QUEST_URL}/quest/${e}`,
								}));
							}}
							popoverClassName="w-[33rem]"
							inputClassName="max-w-[33rem] truncate"
							setSearch={setSearch}
						/>
					</AnimateHeight>
				</div>
				<DialogFooter className="flex justify-end border-t px-5 pt-3 pb-3 w-full sm:justify-end">
					<TooltipWrapper
						tooltip={
							!formFields?.campaignId
								? 'Select a quest to proceed'
								: ''
						}
						align="end"
					>
						<Button
							disabled={!formFields?.campaignId}
							onClick={() => prepareForm()}
						>
							<span>Proceed</span>
						</Button>
					</TooltipWrapper>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ChooseBannerCreateMethod;
