import LandingLayout from "../components/layout/LandingLayout";
import PoHContactUs from "../components/poh/PoHContactUs";
import PoHHero from "../components/poh/PoHHero";
// import PoHDataSources from '../components/poh/PoHDataSources';
import PoHFeatures from "../components/poh/PoHFeatures";
// import PoHHowItWorks from '../components/poh/PoHHowItWorks';
import PoHUseCases from "../components/poh/PoHUseCases";
// import PoHFAQs from '../components/poh/PoHFAQ';

const PoHPage = () => {
  return (
    <LandingLayout>
      <PoHHero />
      <PoHFeatures />
      <PoHUseCases />
      <PoHContactUs />
    </LandingLayout>
  );
};

export default PoHPage;
