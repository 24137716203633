import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { SearchTaskTemplates } from './SearchTaskTemplates';
import { useEffect, useState } from 'react';
import {
	IEditingTasks,
	useCreateCampaignTasks,
} from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { Switch } from '@/components/ui/switch';

export const AddTaskBtn = ({
	sectionId,
}: {
	fullLength?: boolean;
	sectionId?: string;
}) => {
	const { addTask } = useCreateCampaignTasks();
	const [showTaskTemplates, setShowTaskTemplates] = useState(false);

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'j' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				setShowTaskTemplates((showTaskTemplates) => !showTaskTemplates);
			}
		};
		document.addEventListener('keydown', down);
		return () => document.removeEventListener('keydown', down);
	}, []);

	return (
		<>
			<Button
				variant="outline"
				className="w-full font-normal  max-w-[118px]"
				onClick={() => setShowTaskTemplates(true)}
			>
				<i className="bi bi-plus-circle-fill me-2 text-muted-foreground"></i>
				Add Task
			</Button>

			{/* {  fullLength ? (
				<Button
					variant="outline"
					className="w-full font-normal   "
					onClick={() => setShowTaskTemplates(true)}
				>
					<i className="bi bi-plus-circle-fill me-2 text-muted-foreground"></i>
					Add Task
				</Button>
			) : (
				<AddIcon
					setOpen={setShowTaskTemplates}
					addTaskGroup={addTaskGroup}
				/>
			)} */}
			<SearchTaskTemplates
				open={showTaskTemplates}
				setOpen={setShowTaskTemplates}
				sectionId={sectionId}
				addTask={addTask}
			/>
		</>
	);
};

const AddIcon = ({ setOpen, addTaskGroup }: { setOpen: any; addTaskGroup: any }) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					size="icon"
					className="rounded-full flex items-center justify-center"
				>
					<i className="bi bi-plus text-2xl text-muted-foreground"></i>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-40">
				<DropdownMenuItem onClick={() => setOpen(true)}>
					<i className="bi bi-plus-circle me-2 text-muted-foreground"></i>
					Individual Task
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => addTaskGroup()}>
					<i className="bi bi-view-list me-2 text-muted-foreground"></i>
					Task Group
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
