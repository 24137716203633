import { Button } from "@/components/ui/button";
import LandingContainer from "../ui/LandingContainer";
import { pohContent } from "../../content/poh.content";
import { Link } from "react-router-dom";

const PoHFeatures = () => {
  return (
    <div>
      {pohContent.coreFeatures.map((i) => (
        <CoreFeature {...i} />
      ))}
    </div>
  );
};

export default PoHFeatures;

const CoreFeature = ({ title, label, description, features }) => {
  return (
    <LandingContainer>
      <div className="mb-10 pt-40">
        <div className="mx-auto max-w-2xl text-center space-y-4 mb-16">
          <div className="mx-auto max-w-4xl text-center sm:max-w-4xl">
            <h2 className="font-display text-4xl font-extrabold leading-tight text-black sm:text-5xl sm:leading-tight">
              <span className="bg-gradient-to-r from-red-400 to-violet-600 bg-clip-text text-transparent">
                F*CK BOTS
              </span>
              , but how?
            </h2>
            <p className="mt-5 text-gray-600 sm:text-lg">
              Intract is building tech to make sure your users are the real deal
              without compromising their privacy while you build your project
              and take care of other important things{" "}
              <a
                href="https://app.intract.io/auth/register"
                className="text-violet-600"
              >
                like building a community
              </a>
              .
            </p>
          </div>
        </div>
        {/* <CoreFeatureTitle
					title={title}
					description={description}
					label={label}
				/> */}
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-6">
          {features.map((i: any) =>
            i.type === "1" ? <FeatureGrid1 {...i} /> : <FeatureGrid2 {...i} />
          )}
        </div>
      </div>
    </LandingContainer>
  );
};

const FeatureGrid1 = ({ title, description, image }) => {
  return (
    <div className="w-full lg:col-span-2 col-span-1">
      <div className="w-full h-full flex lg:flex-row gap-2 items-center bg-zinc-100 rounded-3xl flex-col">
        <div className="supahub-card-md h-full flex flex-1 flex-col gap-4 justify-between">
          <div className="flex flex-col gap-2">
            <h3 className="text-xl font-medium">{title}</h3>
            <p className="body-regular text-black-800">{description}</p>
          </div>
          <div>
            <Link to="/auth/register">
              <Button className="rounded-full px-5 gap-2">
                Get Started for Free
                <i className="bi-arrow-right"></i>
              </Button>
            </Link>
          </div>
        </div>
        <div className="max-w-[340px] p-2">
          <img
            src={image}
            alt="Collect feedback from different platforms"
            className="aspect-sqaure sm:!aspect-threebytwo object-center object-contain"
            loading="lazy"
            width={800}
            height={800}
            decoding="async"
          />
        </div>
      </div>
    </div>
  );
};

const FeatureGrid2 = ({ title, description, image }) => {
  return (
    <div className="col-span-1">
      <div className="h-full flex flex-col gap-2 items-center justify-between bg-zinc-100 rounded-3xl overflow-hidden">
        <div className="supahub-card-md !pb-4 flex flex-col gap-2">
          <h3 className="text-xl font-medium">{title}</h3>
          <p className="body-regular text-black-800">{description}</p>
        </div>
        <picture className="w-full max-w-lg">
          {/* <img
            src={image}
            alt="Set board privacy"
            className="w-full aspect-threebytwo object-center object-contain"
            loading="lazy"
            width={1200}
            height={800}
            decoding="async"
          /> */}
        </picture>
      </div>
    </div>
  );
};

const CoreFeatureTitle = ({ title, description, label }) => {
  return (
    <div className="mx-auto max-w-2xl text-center space-y-4 mb-16">
      <h2 className="text-sm font-medium text-violet-700">{label}</h2>
      <h3 className="text-4xl font-semibold">{title}</h3>
      <p className="text-zinc-700">{description}</p>
    </div>
  );
};
