import { ColumnDef } from '@tanstack/react-table';
import { IEvent } from '../types/events.type';
import StatusBadge from '@/components/element/badges/StatusBadge';
import dayjs from 'dayjs';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';

export const eventsColumns: ColumnDef<IEvent>[] = [
	{
		accessorKey: 'title',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Name" />
		),
		cell: ({ row }) => (
			<div className="min-w-[350px] py-1">{row.getValue('title')}</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'status',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Status" />
		),
		cell: ({ row }) => (
			<div className="min-w-[120px]">
				<StatusBadge status={row.getValue('status')} />
			</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'startDate',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Start Date" />
		),
		cell: ({ row }) => (
			<div className="flex items-center py-1 max-w-[450px] truncate">
				{dayjs(row.getValue('startDate')).fromNow()}
			</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'endDate',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="End Date" />
		),
		cell: ({ row }) => (
			<div className="flex items-center py-1 max-w-[450px] truncate">
				{dayjs(row.getValue('endDate')).fromNow()}
			</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'numCampaigns',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Campaigns" />
		),
		cell: ({ row }) => (
			<div className="text-xs">
				{row.original?.campaigns?.length} campaigns
			</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		id: 'actions',
		cell: ({ row }) => <div />,
	},
];
