import { ICampaignReward } from '../../types';
import { ethers } from 'ethers';
import { useReadContracts } from '@/hooks/useReadContracts';
import erc20Abi from '@/config/abi/ERC20ABI.json';
import { ERC20RewardABI } from '@/config/abi/ERC20RewardABI';
import { useAccount } from 'wagmi';
import { toast } from 'sonner';
import { useCreateTxn } from '@/hooks/useCreateTxn';
import { ContractType, useGetContract } from '@/hooks/useGetContract';
import {
	getTokenRewardTransferSignature,
	updateCampaignAdminAddress,
	updateTokenRewardApprovalTxn,
	updateTokenRewardTransferTxn,
} from '../../services/campaigns.service';
import { useMemo, useState } from 'react';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

export const useDepositToken = ({
	campaignId,
	tokenReward,
	launchCampaign,
}: {
	campaignId: string;
	tokenReward: ICampaignReward;
	launchCampaign?: any;
}) => {
	const { contract } = useGetContract(
		tokenReward?.tokenReward?.chainId,
		ContractType.IntractCampaignRewardToken,
	);

	const [status, setStatus] = useState('Connect Wallet');
	const [isLoading, setIsLoading] = useState(false);
	const chainId = tokenReward?.tokenReward?.chainId;
	const { address, chainId: selectedChainId } = useAccount();
	const { readContract } = useReadContracts();
	const { startTxn } = useCreateTxn();

	console.log(address, '==account/admin address');

	const requiredAmount = useMemo(() => {
		if (!tokenReward) return '';
		return ethers.utils.parseUnits(
			(
				Number(tokenReward?.tokenReward?.tokenAmountPerUser) *
				tokenReward?.numRewards
			).toString(),
			tokenReward?.tokenReward?.tokenDecimals,
		);
	}, [tokenReward]);

	const startProcess = async () => {
		try {
			setIsLoading(true);
			const balance = await readContract({
				chainId,
				contractAddress: tokenReward?.tokenReward?.tokenAddress,
				ABI: erc20Abi,
				fnName: 'balanceOf',
				args: [address],
			});
			if (!ethers.BigNumber.from(balance).gte(requiredAmount)) {
				toast.error(
					'Insufficient balance, please check if you have connected the correct wallet.',
				);
				return;
			}
			await startApprovalTransaction();
			await startTransferTransaction();
			return true;
		} catch (err) {
			handleErrorMessage(err);
			console.log(err);
			return false;
		} finally {
			setIsLoading(false);
		}
	};
	const startApprovalTransaction = async () => {
		setStatus('(1/2) Approve Token Transaction');
		const receiver = contract.address;
		const args = [receiver, requiredAmount];

		console.log(receiver, requiredAmount, '== startTxn resp');
		console.log(args, '==args');

		const approvedAmount = await readContract({
			chainId,
			contractAddress: tokenReward?.tokenReward?.tokenAddress,
			ABI: erc20Abi,
			fnName: 'allowance',
			args: [address, receiver],
		});
		console.log(approvedAmount, '== approvedAmount');
		if (ethers.BigNumber.from(approvedAmount).gte(requiredAmount)) {
			analytics.track(TrackingEvents.LaunchQuestTokenApproved, {
				preApproved: true,
				chainId,
			});
			await updateCampaignAdminAddress(campaignId, {
				adminAddress: address,
			});
			return;
		}
		const txn = await startTxn({
			chainId,
			contractAddress: tokenReward?.tokenReward?.tokenAddress,
			ABI: erc20Abi,
			fnName: 'approve',
			args,
		});

		console.log(txn, '== startTxn resp');

		if (!txn) {
			throw new Error(
				'Token Approval Transaction failed, please reach out to support',
			);
		}
		analytics.track(TrackingEvents.LaunchQuestTokenApproved, {
			preApproved: false,
			chainId,
		});
		await updateTokenRewardApprovalTxn(campaignId, {
			adminAddress: txn.account,
			rewardId: tokenReward._id,
			txHash: txn.transactionHash,
			status: 'completed',
			chainId: chainId,
			receipt: txn.receipt,
		});
		return;
	};
	const startTransferTransaction = async () => {
		const res = await getTokenRewardTransferSignature(campaignId);
		const signatureData = res.txnData;
		setStatus('(2/2) Transfer Token Transaction');

		const agrs = formatArgs(signatureData.functionParams);

		const txn = await startTxn({
			chainId,
			contractAddress: contract.address,
			ABI: ERC20RewardABI,
			fnName: signatureData.functionName,
			args: agrs,
		});
		if (!txn) {
			throw new Error(
				'Transfer Transaction failed, please reach out to support',
			);
		}
		const receipt = txn.receipt;
		if (!receipt.decodedLogs) {
			throw new Error(
				'We could not verify the transaction with the required logs. Please try again.',
			);
		}
		await updateTokenRewardTransferTxn(campaignId, {
			txHash: txn.transactionHash,
			status: 'completed',
			chainId: chainId,
			receipt: txn.receipt,
		});
		analytics.track(TrackingEvents.LaunchQuestTokenTransferred, {
			chainId,
		});
		setStatus('Transaction Completed');
	};

	return {
		startProcess,
		startApprovalTransaction,
		startTransferTransaction,
		isLoading,
		isConnected: !!address,
		isChainSelected: chainId === selectedChainId,
		status,
	};
};

function formatArgs(args) {
	return args.map((arg) => {
		if (Array.isArray(arg)) {
			return arg.map((item) => ({
				rewardId: ethers.BigNumber.from(item.rewardId.hex),
				tokenAddress: item.tokenAddress,
				numRewards: ethers.BigNumber.from(item?.numRewards.hex),
			}));
		} else if (arg.type && arg.type === 'BigNumber') {
			return ethers.BigNumber.from(arg.hex);
		} else {
			return arg;
		}
	});
}
