import { Card, CardContent } from '@/components/ui/card';
import { CampaignRewardType, ICampaignAddReward } from '@/features/campaigns/types';
import RewardCardHeader from '../../others/RewardCardHeader';
import { TokenRewardMethod } from './TokenRewardMethod';
import { SelectTokenAndChain } from './SelectTokenAndChain';
import { TokenRewardWinners } from './TokenRewardWinners';
import { useMemo } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { TrackingEvents } from '@/types/tracking.type';

const AddERC20TokenReward = ({
	tokenReward,
	setTokenReward,
	errors,
	noEndTime,
}: {
	tokenReward: ICampaignAddReward;
	setTokenReward: (reward: ICampaignAddReward) => void;
	errors: {
		method: boolean | string;
		chain: boolean;
		token: boolean;
		winners: boolean;
		reward: boolean;
		expected: boolean;
	};
	noEndTime: boolean;
}) => {
	const totalRewardPool = useMemo(() => {
		if (
			!tokenReward?.tokenReward?.tokenAmountPerUser ||
			!tokenReward?.numRewards ||
			!tokenReward?.tokenReward?.tokenSymbol
		) {
			return 0;
		}
		return (
			+tokenReward?.tokenReward?.tokenAmountPerUser * tokenReward?.numRewards
		);
	}, [
		tokenReward.numRewards,
		tokenReward.tokenReward?.tokenAmountPerUser,
		tokenReward?.tokenReward?.tokenSymbol,
	]);

	return (
		<>
			<Card className="mb-10 mt-10 shadow-none">
				<RewardCardHeader
					title="ERC20 Tokens Rewards"
					description="ERC20 tokens are fungible tokens that are compatible with the Ethereum network."
					tooltip={
						'Quests with stable-coin rewards, with a pool of at least $1000 get the most completions. Higher is better.'
					}
					trackingEvent={TrackingEvents.ERC20ProTipViewed}
				/>
				<CardContent className="px-0 pb-3">
					<TokenRewardMethod
						tokenReward={tokenReward}
						setTokenReward={setTokenReward}
						errors={errors}
						noEndTime={noEndTime}
					/>
					<SelectTokenAndChain
						tokenReward={tokenReward}
						setTokenReward={setTokenReward}
						errors={errors as any}
					/>
					{tokenReward?.method === CampaignRewardType.Leaderboard ? (
						<div className="mb-8 px-6 flex items-center">
							<Checkbox
								checked={tokenReward?.autoRewardDistribution}
								onCheckedChange={(check) =>
									setTokenReward({
										...tokenReward,
										autoRewardDistribution: check as boolean,
									})
								}
								className="me-2"
							/>
							<div className="text-sm ">
								Allow auto distribution of rewards?
							</div>
						</div>
					) : null}
					<TokenRewardWinners
						tokenReward={tokenReward}
						setTokenReward={setTokenReward}
						errors={errors as any}
					/>
				</CardContent>
				{totalRewardPool ? (
					<CardContent className=" py-5 border-t">
						<div className="flex justify-between items-center">
							<div className="me-8">
								<div className="text-sm font-medium mb-1">
									<span className="text-muted-foreground">
										Total Reward Pool:
									</span>{' '}
									${totalRewardPool}
									{` `}
									{tokenReward?.tokenReward?.tokenSymbol}
								</div>
								<div className="text-xs text-muted-foreground">
									You can test the campaign before launching it and
									depositing the tokens
								</div>
							</div>
						</div>
					</CardContent>
				) : (
					<></>
				)}
			</Card>
		</>
	);
};

export default AddERC20TokenReward;
