import TooltipWrapper from './TooltipWrapper';

const ProTip = ({
	content,
	side = 'bottom',
	trackingEvent,
}: {
	content: string | JSX.Element;
	side?: 'top' | 'right' | 'bottom' | 'left';
	trackingEvent?: string;
}) => {
	return (
		<TooltipWrapper
			tooltip={content}
			side={side}
			tooltipContentClass="text-slate-500 text-sm leading-normal w-fit"
			align="start"
			contentClassName="shadow-none"
			trackingEvent={trackingEvent}
		>
			<div className="cursor-pointer text-slate-600 text-sm font-light flex items-center w-full">
				<i className="bi bi-info-circle me-1"></i>
				Pro Tip
			</div>
		</TooltipWrapper>
	);
};

export default ProTip;
