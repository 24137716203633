import { Label } from '@/components/ui/label';
import CampaignAdvancedConfig from './advanced/CampaignAdvancedConfig';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import InputText from '@/components/element/inputs/InputText';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import InputDuration from '@/components/element/inputs/input-duration/InputDuration';
import { QuillEditor } from '@/components/element/rich-text-editor/QuillEditor';
import { useState } from 'react';
import AddNewCustomChain from './AddNewCustomChain';
import QuestReferralConfig from './QuestReferralConfig';
import ProTip from '@/components/element/tooltips/ProTip';
import { TrackingEvents } from '@/types/tracking.type';

const AddDetails = () => {
	const { mode, details, setDetails, setIsDirty, errors } = useCreateCampaign();
	const [showNewChain, setShowNewChain] = useState(false);

	const editDetails = (
		key: string,
		value: string | Date | boolean | { x: number; y: number },
	) => {
		setIsDirty(true);
		setDetails((prev) => ({ ...prev, [key]: value }));
	};

	return (
		<div>
			<div className="flex justify-center items-center py-10">
				<div className="mb-5 w-[600px] relative">
					<>
						<div className="grid grid-cols-12 gap-4 second-element">
							<InputText
								label="Title"
								placeholder="Launch on Intract"
								className="col-span-8"
								value={details.name}
								setValue={(e) => editDetails('name', e)}
								error={errors?.details?.name}
								inputClassName="bg-white dark:bg-black"
								errorText={'Please enter a valid title'}
								required
							/>
							<div className="col-span-4">
								<Label className="font- mb-2 block">Chain</Label>
								<SelectNetwork2
									value={details.network}
									setValue={(selectedNetwork) => {
										setDetails((prev) => ({
											...prev,
											network: selectedNetwork.chainId,
											isCustomChain:
												selectedNetwork.type === 'CUSTOM',
										}));
									}}
									showNonEVM
									showAddCustomChain
									setShowAddCustomChain={setShowNewChain}
									fetchEnterpriseChains
									addCustomChain
								/>
							</div>
						</div>
						{errors?.details?.name ? null : (
							<p className="text-muted-foreground text-xs mt-1">
								Keep it concise and actionable, Eg: “Bride Via
								intract”
							</p>
						)}
					</>
					<div className="mt-10">
						<Label className="font-normal mb-3 flex justify-between">
							Description
							<ProTip
								content={
									'Provide a thorough description of your campaign: its purpose, project’s background, any info to understand tasks, and especially rewards - their eligibility, distribution and claiming process.'
								}
								side="bottom"
								trackingEvent={
									TrackingEvents.DescriptionProTipViewed
								}
							/>
						</Label>
						<QuillEditor
							value={details.description}
							setValue={(e) => editDetails('description', e)}
							placeholder="Describe your campaign here..."
							error={errors?.details?.description}
							errorText={'Please enter a valid description'}
						/>
					</div>
					<DropzoneWithReposition
						className="mt-10"
						file={details.bannerFile}
						fileLink={details.bannerLink}
						clearSelection={() => {
							setDetails((p) => ({
								...p,
								bannerFile: null,
								bannerLink: '',
							}));
						}}
						setFile={(file: File | File[], fileLink: string) => {
							setDetails((prev) => ({
								...prev,
								bannerFile: file,
								bannerLink: fileLink,
							}));
						}}
						description={`${details.bannerFile || details.bannerLink ? 'Feature your logo along with ours and highlight the rewards! (Recommended size: 1920x1080px)' : 'Recommended size: 1920x1080px'} `}
						label="Banner"
						cta="Upload your banner"
						aspectRatio={1920 / 1080}
						error={errors?.details?.bannerFile}
						errorText="Please upload a valid image or video file."
						onImageReposition={({ x, y }) => {
							setDetails((prev) => ({
								...prev,
								bannerPosition: { x, y },
							}));
						}}
						bannerPosition={details.bannerPosition}
						allowedFiles={['images']}
						maxSize={3}
						required
						proTip={
							<ul className="text-xs text-slate-400 space-y-2 list-disc">
								<li>
									Feature your logo along with ours, your quest
									title, and highlight the rewards!
								</li>
								<li>Specify the reward type and its utility</li>
							</ul>
						}
						showPlaceHolderImage={
							'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/f3103a101af2491f9b224fdc9ba1e8a4.svg'
						}
					/>
					<div className="my-10 mt-16">
						<div>
							<div className="mb-3 flex justify-between items-center">
								<div className="">
									<div className="text-sm font-medium">
										<i className="bi-calendar me-2"></i>
										Campaign Durations
									</div>
									<div className="text-xs text-muted-foreground mt-1">
										Set the duration of the campaign
									</div>
								</div>
								<ProTip
									content={
										'Ideal duration for campaigns are ~2 weeks. Enough time for word to spread, users to complete tasks.'
									}
									side="bottom"
									trackingEvent={
										TrackingEvents.DurationProTipViewed
									}
								/>
							</div>
							<InputDuration
								className=""
								startDate={details?.startDate}
								endDate={details?.endDate}
								setStartDate={(date) =>
									editDetails('startDate', date)
								}
								setEndDate={(date) => editDetails('endDate', date)}
								startImmediately={details?.startImmediately}
								setStartImmediately={(e) =>
									editDetails('startImmediately', e)
								}
								timezone={details?.timezone}
								setTimezone={(e) => editDetails('timezone', e)}
								noDeadline={details?.noEndTime}
								setNoDeadline={(e) => editDetails('noEndTime', e)}
							/>
						</div>
						{errors?.details?.duration && (
							<p className="text-red-500 text-xs mt-3">
								{(errors?.details?.duration as string) ||
									'Please enter a valid duration.'}
							</p>
						)}
					</div>
					<QuestReferralConfig
						details={details}
						setDetails={setDetails}
						errors={errors?.details?.referral}
					/>

					{mode !== 'simple' && <CampaignAdvancedConfig />}

					<AddNewCustomChain
						show={showNewChain}
						setShow={setShowNewChain}
					/>
				</div>
			</div>
		</div>
	);
};

export default AddDetails;
