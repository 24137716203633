import GeoChart from '@/components/charts/GeoChart';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { useMemo } from 'react';
import worldmap from '@/assets/worldmap.json';

const UserGeoLocationMap = () => {
	const { campaign } = useCampaignReport();

	const createColorMap = (minCount: number, maxCount: number) => {
		const colorRange = [
			'rgba(189, 177, 222, 1)', // Least value
			'rgba(119, 99, 175, 1)',
			'rgba(99, 77, 163, 1)',
			'rgba(80, 54, 151, 1)',
			'rgba(60, 32, 140, 1)', // Greatest value
		];

		const getColor = (count: number, isRestricted: boolean) => {
			if (isRestricted) return 'rgba(241, 71, 79, 1)';

			if (count === 0 && !isRestricted) return 'rgba(189, 177, 222, 1)'; // Use the least value color for countries with 0 count

			if (count > maxCount) return colorRange[colorRange.length - 1]; // Max color for values exceeding maxCount

			// Normalize the count within the range and determine the corresponding color
			const normalizedCount = (count - minCount) / (maxCount - minCount);
			const colorIndex = Math.floor(normalizedCount * (colorRange.length - 1));
			return colorRange[colorIndex];
		};

		return getColor;
	};

	const data = useMemo(() => {
		const restrictedCountries =
			(campaign?.restrictedLocations ?? [])
				.map((loc) => loc?.country)
				.filter(Boolean) || [];
		const counts = campaign.geolocationData.map((i) => i.count);
		const minCount = Math.min(...counts.filter((count) => count > 0));
		const maxCount = Math.max(...counts);

		const getColor = createColorMap(minCount, maxCount);

		// Process geolocation data
		const geoData = campaign?.geolocationData?.map((i) => {
			const isRestricted = restrictedCountries.includes(i.country);
			const country = worldmap.features.find(
				(c) => c.properties.name === i.country,
			)?.id;

			return {
				value: isRestricted ? 0 : i.count,
				id: country,
				color: isRestricted
					? 'rgba(241, 71, 79, 1)'
					: getColor(i.count, isRestricted),
				isRestricted,
			};
		});

		// Add restricted countries that are not in geolocation data
		const additionalRestrictedCountries = restrictedCountries
			?.filter(
				(country) =>
					!campaign.geolocationData.some((i) => i.country === country),
			)
			.map((country) => {
				const countryId = worldmap.features.find(
					(c) => c.properties.name === country,
				)?.id;
				return {
					id: countryId,
					value: 0,
					color: 'rgba(241, 71, 79, 1)',
					isRestricted: true,
				};
			});

		return [...geoData, ...additionalRestrictedCountries].sort(
			(a, b) => b.value - a.value,
		);
	}, [campaign?.geolocationData, campaign?.restrictedLocations]);

	return (
		<Card className="mt-10">
			<CardHeader className="">
				<h2 className="text-lg font-semibold">User Locations</h2>
			</CardHeader>
			<CardContent className="p-0">
				<div className="h-[400px]">
					<GeoChart
						height={'360px'}
						data={data}
						completedUsersCount={campaign?.completedUsersCount}
					/>
					<div className="text-xs mt-3 px-3 text-muted-foreground">
						Geo data is only available for users who have participated
						after 20th April 2024.
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default UserGeoLocationMap;
