import ProTip from '@/components/element/tooltips/ProTip';
import { CardHeader, CardTitle } from '@/components/ui/card';
import { TrackingEvents } from '@/types/tracking.type';

const RewardCardHeader = ({
	title,
	description,
	tooltip,
	trackingEvent,
}: {
	title: string;
	description: string;
	tooltip?: string | JSX.Element;
	trackingEvent?: TrackingEvents;
}) => {
	return (
		<CardHeader className="w-full">
			<CardTitle className="w-full ">
				<div className="flex justify-between align-top w-full">
					<div className="w-full">
						<div className="font-medium flex justify-between w-full">
							{title}
							{tooltip ? (
								<ProTip
									content={tooltip}
									trackingEvent={trackingEvent}
								/>
							) : null}
						</div>
						<div className="font-normal text-xs text-muted-foreground mt-1">
							{description}
						</div>
					</div>
				</div>
			</CardTitle>
		</CardHeader>
	);
};

export default RewardCardHeader;
