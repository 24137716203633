import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Skeleton } from '@/components/ui/skeleton';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { useCreateCampaignNavigation } from '@/features/campaigns/hooks/create/useCreateCampaignNavigation';
import { CreateCampaignSections } from '@/features/campaigns/types';
import { IGlobalConfigData } from '@/features/getting-started/hooks/useGetGlobalConfigs';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { useState } from 'react';

const CampaignCreateNavigation = ({
	enterprise,
	setShowApprovalCompletionDialog,
	showBestPractices,
	setShowBestPractices,
}) => {
	const { section } = useCreateCampaign();
	const {
		moveForward,
		moveBack,
		saveDraft,
		isLoading,
		launchCampaign,
		testCampaign,
	} = useCreateCampaignNavigation();

	return (
		<div className="flex justify-between items-center  border-t p-3 pb-3 px-4 relative">
			<div className="space-x-4 flex items-center">
				{section !== CreateCampaignSections.Details && (
					<Button size="icon" variant="outline" onClick={moveBack}>
						<i className="bi bi-chevron-left"></i>
					</Button>
				)}
				<Button
					variant="outline"
					className="min-w-[110px]"
					onClick={() => saveDraft(false)}
					disabled={isLoading.draft}
				>
					{isLoading.draft && <Spinner className="me-2" />}
					<span>Save</span>
				</Button>
				<Button
					variant="outline"
					className="min-w-[110px]"
					onClick={() => saveDraft(true)}
					disabled={isLoading.preview}
				>
					{isLoading.preview && <Spinner className="me-2" />}
					<span>Preview</span>
				</Button>
			</div>
			<div className="absolute left-0 right-0 mx-auto w-[400px]">
				<div className="text-xs"></div>
			</div>
			<div className="flex justify-end space-x-4 items-center">
				<BestPracticesGuide
					showBestPractices={showBestPractices}
					setShowBestPractices={setShowBestPractices}
				/>
				{section === CreateCampaignSections.Rewards ? (
					<div className="flex items-center space-x-4">
						<Button
							className=" px-10"
							onClick={testCampaign}
							disabled={isLoading.test}
						>
							{isLoading.test && <Spinner className="me-2" />}
							<span>Test Campaign</span>
						</Button>
					</div>
				) : (
					<Button className=" px-10" onClick={moveForward}>
						<span>Next</span>
						<i className="bi bi-arrow-right ms-2"></i>
					</Button>
				)}
			</div>
		</div>
	);
};

export default CampaignCreateNavigation;

const BestPracticesGuide = ({ showBestPractices, setShowBestPractices }) => {
	const globalConfigs: IGlobalConfigData = queryClient.getQueryData([
		'global-configs',
	]);
	const [currentIndex, setCurrentIndex] = useState(0);

	const handlePrevious = () => {
		setCurrentIndex((prev) => Math.max(0, prev - 1));
		analytics.track(TrackingEvents.PreviousTipClicked, {});
	};

	const handleNext = () => {
		setCurrentIndex((prev) =>
			Math.min(globalConfigs?.questNudges.length - 1, prev + 1),
		);
		analytics.track(TrackingEvents.NextTipClicked, {});
	};

	return (
		<Popover open={showBestPractices} onOpenChange={setShowBestPractices}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					onClick={() => {
						setShowBestPractices(true);
						analytics.track(TrackingEvents.BestPracticesClicked, {});
					}}
				>
					<i className="bi-lightbulb me-2"></i>
					Best Practices
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className="w-[396px] mb-1 rounded-lg relative z-[31]"
				align="end"
				side="top"
				onInteractOutside={(e) => e.preventDefault()} // This prevents closing on outside clicks
			>
				<i
					className="bi-x text-xl absolute top-1 right-1 cursor-pointer px-1 text-slate-400 hover:text-slate-700"
					onClick={() => {
						setShowBestPractices(false);
						analytics.track(TrackingEvents.TipsClosed, {});
					}}
				></i>

				{globalConfigs?.questNudges?.length > 0 ? (
					<div className="space-y-3">
						<div key={currentIndex} className="space-y-1.5">
							<p className="text-slate-700 text-sm leading-4 font-semibold flex justify-between items-start">
								{globalConfigs?.questNudges[currentIndex].title}
							</p>
							<p className="text-sm leading-4 text-slate-600 ">
								{
									globalConfigs?.questNudges[currentIndex]
										.description
								}
							</p>
						</div>
						<div className="space-x-2 mt-3 ">
							<Button
								variant="ghost"
								disabled={currentIndex === 0}
								onClick={handlePrevious}
							>
								Previous
							</Button>
							<Button
								variant="ghost"
								disabled={
									currentIndex ===
									globalConfigs?.questNudges.length - 1
								}
								onClick={handleNext}
								className="text-purple-500 hover:text-purple-500 hover:bg-purple-50 focus:bg-purple-50 focus:text-purple-500"
							>
								Next
							</Button>
						</div>
					</div>
				) : (
					<div key={currentIndex} className="">
						<Skeleton className="w-2/4 h-4 mb-4" />
						<>
							<Skeleton className="w-[90%] h-4 mb-1" />
							<Skeleton className="w-[90%] h-4" />
						</>
						<div className="space-x-2 mt-4 flex">
							<Skeleton className="w-20 h-7" />
							<Skeleton className="w-20 h-7" />
						</div>
					</div>
				)}
			</PopoverContent>
		</Popover>
	);
};
