import InputSelect2 from '@/components/element/inputs/InputSelect2';
import InputText from '@/components/element/inputs/InputText';
import InputTextArea from '@/components/element/inputs/InputTextArea';
import { QUEST_URL } from '@/config';
import { useGetAdminEvents } from '@/features/admin/hooks/useGetAdminEvents';
import { HomepageCarouselSourceEnum } from '@/features/admin/types/cms.types';
import { validateCompleteURL } from '@/utils/parsers';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

const AddBannerDetails = ({ formFields, setFormFields, formErrors }) => {
	const {
		results: events,
		search,
		setSearch,
		filters,
		setFilters,
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading,
		refetch,
	} = useGetAdminEvents({
		enterpriseId: formFields.enterpriseId,
	});

	const options = [
		{
			type: 'item',
			label: 'Quest',
			onClick: () =>
				setFormFields((prev) => ({
					...prev,
					source: HomepageCarouselSourceEnum.Quest,
				})),
			value: HomepageCarouselSourceEnum.Quest,
		},
		{
			type: 'item',
			label: 'Event',
			onClick: () =>
				setFormFields((prev) => ({
					...prev,
					source: HomepageCarouselSourceEnum.Event,
				})),
			value: HomepageCarouselSourceEnum.Event,
		},
		{
			type: 'item',
			label: 'Other',
			onClick: () =>
				setFormFields((prev) => ({
					...prev,
					source: HomepageCarouselSourceEnum.Other,
				})),
			value: HomepageCarouselSourceEnum.Other,
		},
	];

	return (
		<div className="space-y-5">
			<InputText
				label="Enterprise Id"
				placeholder="Enter enterprise id"
				value={formFields.enterpriseId}
				setValue={(e) => setFormFields({ ...formFields, enterpriseId: e })}
				error={formErrors['enterpriseId']}
				errorText={formErrors['enterpriseId']}
				required
			/>
			<InputText
				label="Banner Title"
				placeholder="Enter banner title"
				value={formFields.title}
				setValue={(e) => setFormFields({ ...formFields, title: e })}
				error={formErrors['title']}
				errorText={formErrors['title']}
				required
			/>

			<InputText
				label="Banner Link"
				placeholder="Enter banner link"
				value={formFields.link}
				setValue={(e) => setFormFields({ ...formFields, link: e })}
				error={formErrors['link']}
				errorText={formErrors['link']}
				required
				onBlur={() => {
					if (formFields.link) {
						setFormFields({
							...formFields,
							link: validateCompleteURL(formFields.link),
						});
					}
				}}
			/>
			<InputTextArea
				label="Banner Description"
				placeholder="Enter banner description"
				value={formFields.description}
				setValue={(e) => setFormFields({ ...formFields, description: e })}
				error={formErrors['description']}
				errorText={formErrors['description']}
				required
			/>
		</div>
	);
};

export default AddBannerDetails;
