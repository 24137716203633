import { useQuery } from '@tanstack/react-query';
import { fetchGlobalConfigs } from '../services/home.service';

export interface IGlobalConfigData {
	globalConfigs: any,
	questNudges: any[],
}

export const useGetGlobalConfigs = () => {
	const { data, isLoading } = useQuery({
		queryKey: ['global-configs'],
		queryFn: () => fetchGlobalConfigs(),
	});

	return {
		globalConfigs: data?.configs || {},
		isLoading,
		questNudges: data?.questNudges || [],
	};
};
