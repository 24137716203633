import { Button } from "@/components/ui/button";
import LandingContainer from "../ui/LandingContainer";
import { Link } from "react-router-dom";
import { pohContent } from "../../content/poh.content";

const FeaturesHero = () => {
  const content = pohContent.hero;
  const contentData = pohContent.dataSources;

  return (
    <div>
      <LandingContainer>
        <div className="absolute left-[24px] top-0 z-0 xl:left-[-354px] xl:top-[84px] w-screen h-screen ">
          <div className="pointer-events-none absolute z-[0] h-[676px] w-[700px] rounded-full scale-[1.75] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-violet-500 via-red-300 to-transparent to-70% opacity-[22%]"></div>
        </div>
        <div className=" z-10 relative py-40 z-4">
          <div className="flex flex-col items-center justify-center ">
            <div className="text-sm rounded-full bg-slate-100 px-4 py-1">
              {content.label}
            </div>
            <div className="relative mb-3 mt-4">
              <h1 className=" text-5xl text-center font-medium  leading-snug  selection:text-black">
                <span className="bg-gradient-to-r from-red-400 to-violet-600 bg-clip-text text-transparent">
                  Human
                </span>
                {content.title}
              </h1>
            </div>
            <div
              className="text-lg transition-colors text-center text-zinc-500  w-full"
              style={{ maxWidth: 730 }}
            >
              <p>{content.description}</p>
            </div>
            <div className="link-group flex w-full  gap-4 xxs:flex-row md:w-auto items-center pt-10  text-center justify-center md:items-center md:text-center md:justify-center ">
              <a
                href="https://calendly.com/kush-intract/30min"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="rounded-xl" size="lg">
                  <span className="me-2">Contact Usssss</span>
                  <svg
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    fill="none"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.44"
                      d="M4.5 2.25 8.25 6 4.5 9.75"
                    />
                  </svg>
                </Button>
              </a>
              <a
                href="https://static.intract.io/Whitepaper.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="rounded-xl" size="lg" variant="outline">
                  <span className="me-2">Learn more</span>
                  <svg
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    fill="none"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.44"
                      d="M4.5 2.25 8.25 6 4.5 9.75"
                    />
                  </svg>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </LandingContainer>
      <div className={`w-full`}>
        <div className="py-4 overflow-hidden w-full bg-white">
          <p className="mx-auto max-w-sm text-center font-medium sm:max-w-xl sm:text-lg mb-4">
            {contentData.title}
          </p>

          <div className="flex flex-row items-center gap-20 animate-marquee whitespace-nowrap">
            {[...contentData.logos, ...contentData.logos].map((i, index) => (
              <img
                key={index}
                alt={i.name}
                loading="lazy"
                decoding="async"
                className="blur-0 text-center transition-all hover:grayscale-0 lg:h-20 object-contain w-16 h-8 flex"
                src={i.img}
                style={{ color: "transparent" }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesHero;
