import { useState } from 'react';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';
import { upsertBanner } from '../../services/cms.service';
import { uploadFile } from '@/services/utility.service';
import { validateCompleteURL } from '@/utils/parsers';
import { handleErrorMessage } from '@/utils/notifications';
import { HomepageCarouselSourceEnum } from '../../types/cms.types';

export interface IBannerCreate {
	_id: string;
	campaignId: string;
	banner: string;
	imageFile: any;
	imagePosition: {
		x: number;
		y: number;
	};
	enterpriseId: string;
	title: string;
	description: string;
	link: string;
	index: number;
	startDate: Date;
	endDate: Date;
	startImmediately: boolean;
	noEndTime: boolean;
	timezone: string;
	source: HomepageCarouselSourceEnum;
	sourceId?: string;
}
const initialState = {
	_id: '',
	campaignId: '',
	banner: '',
	imageFile: null,
	imagePosition: {
		x: 0,
		y: 0,
	},
	enterpriseId: '',
	title: '',
	description: '',
	link: '',
	index: 0,
	startDate: new Date(),
	endDate: dayjs().add(7, 'day').toDate(),
	startImmediately: true,
	noEndTime: false,
	timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	source: HomepageCarouselSourceEnum.Quest,
	sourceId: '',
};
const useBannerCreate = ({ setOpenCreateBanner }) => {
	const [formFields, setFormFields] = useState<IBannerCreate>(initialState);
	const [formErrors, setFormErrors] = useState({});
	const [isUploading, setIsUploading] = useState(false);

	const resetForm = () => {
		setFormFields(initialState);
		setFormErrors({});
		setOpenCreateBanner(false);
	};

	const validateForm = () => {
		const errors: any = {};
		if (!formFields.title) {
			errors.title = 'Title is required';
		}
		if (!formFields.description) {
			errors.description = 'Description is required';
		}
		if (!formFields.banner) {
			errors.banner = 'Banner Image is required';
		}
		if (!formFields.link) {
			errors.link = 'Link is required';
		}
		if (!formFields.startDate) {
			errors.startDate = 'Start date is required';
		}
		if (!formFields.endDate) {
			errors.endDate = 'End date is required';
		}
		if (
			(formFields.source === HomepageCarouselSourceEnum.Quest ||
				formFields.source === HomepageCarouselSourceEnum.Event) &&
			!formFields.sourceId
		) {
			errors.sourceId = `${formFields.source} Id is required`;
		}
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const upsertMutation = useMutation({
		mutationFn: upsertBanner,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['cms-banners'],
			});
			resetForm();
		},
		onError: (error) => {
			handleErrorMessage(error);
		},
	});

	const handleSubmit = () => {
		if (!validateForm()) return;

		if (formFields.imageFile && formFields.banner.includes('blob:')) {
			setIsUploading(true);
			uploadFile(formFields.imageFile).then((res) => {
				setFormFields({
					...formFields,
					banner: res,
				});

				upsertMutation.mutateAsync({
					...formFields,
					bannerId: formFields._id,
					banner: res,
					link: validateCompleteURL(formFields.link),
				});
				setIsUploading(false);
			});
		} else {
			upsertMutation.mutateAsync({
				...formFields,
				bannerId: formFields._id,
			});
		}
	};

	return {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		handleSubmit,
		resetForm,
		isPending: upsertMutation.isPending || isUploading,
	};
};

export default useBannerCreate;
