import { Card, CardContent } from '@/components/ui/card';
import RewardCardHeader from '../others/RewardCardHeader';
import RewardMethodSelect from '../input/RewardMethodSelect';
import { useMemo } from 'react';
import { CampaignRewardType, ICampaignAddReward } from '@/features/campaigns/types';
import InputText from '@/components/element/inputs/InputText';
import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import { Checkbox } from '@/components/ui/checkbox';
import { TrackingEvents } from '@/types/tracking.type';

const AddOfficialPoints = ({
	officialPointsReward,
	setOfficialPointsReward,
	errors,
	noEndTime,
}: {
	officialPointsReward: ICampaignAddReward;
	setOfficialPointsReward: (reward: ICampaignAddReward) => void;
	noEndTime: boolean;
	errors: {
		name: boolean;
		image: boolean;
		winners: boolean;
		method: boolean | string;
		enableCustomPoints?: boolean;
		pointsPerUser?: boolean;
	};
}) => {
	const allowedMethods = useMemo(() => {
		const methods = [CampaignRewardType.Fcfs, CampaignRewardType.Unlimited];
		if (!noEndTime) {
			methods.push(CampaignRewardType.LuckyDraw);
			methods.push(CampaignRewardType.Leaderboard);
		}
		return methods;
	}, []);
	return (
		<Card className="mb-10 mt-10 shadow-none">
			<RewardCardHeader
				title="Official Points"
				description="Official Points are fungible tokens that are compatible with the Ethereum network."
				tooltip="Choose this method only if you have an active airdrop points system. Clarify how users can claim their points."
				trackingEvent={TrackingEvents.OfficialPointsProTipViewed}
			/>
			<CardContent>
				<div className="grid grid-cols-9 gap-8">
					<div className="space-y-6 col-span-5">
						<div>
							<div className="text-sm font-medium mb-1">
								Reward Method
							</div>
							<RewardMethodSelect
								allowedMethods={allowedMethods}
								value={officialPointsReward?.method}
								setValue={(value) =>
									setOfficialPointsReward({
										...officialPointsReward,
										method: value as CampaignRewardType,
									})
								}
								errorText={
									errors?.method || 'Please select a reward method'
								}
								error={!!errors?.method}
							/>
						</div>
						<InputText
							label="Reward Name"
							placeholder="Intract Whitelist to early holders"
							setValue={(e) => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										name: e,
									},
								});
							}}
							value={officialPointsReward?.whitelistReward?.name}
							error={errors?.name}
							errorText="Please enter a reward name"
						/>
						{officialPointsReward?.method ===
						CampaignRewardType.Leaderboard ? (
							<div className="mb-8 flex items-center">
								<Checkbox
									checked={
										officialPointsReward?.autoRewardDistribution
									}
									onCheckedChange={(check) =>
										setOfficialPointsReward({
											...officialPointsReward,
											autoRewardDistribution: check as boolean,
										})
									}
									className="me-2"
								/>
								<div className="text-sm ">
									Allow auto distribution of rewards?
								</div>
							</div>
						) : null}
						{officialPointsReward?.method !==
							CampaignRewardType.Unlimited && (
							<InputText
								label="Total Winners"
								placeholder="100"
								type="number"
								setValue={(e) => {
									setOfficialPointsReward({
										...officialPointsReward,
										numRewards: parseInt(e?.toString()),
									});
								}}
								value={officialPointsReward?.numRewards}
								error={errors?.winners}
								errorText="Please enter a number of winners"
							/>
						)}
						<InputText
							label="Points per Winner"
							placeholder="100"
							type="number"
							setValue={(e) => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										pointsPerUser: parseInt(e?.toString()),
									},
								});
							}}
							value={
								officialPointsReward?.whitelistReward?.pointsPerUser
							}
							error={errors?.pointsPerUser}
							errorText="Please enter a points per winners"
						/>
					</div>
					<div className="col-span-4">
						<DropzoneWithReposition
							file={officialPointsReward.whitelistReward?.nftImage}
							fileLink={officialPointsReward.whitelistReward?.image}
							clearSelection={() => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										nftImage: null,
										image: '',
									},
								});
							}}
							setFile={(file: File | File[], fileLink: string) => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										nftImage: file,
										image: fileLink,
									},
								});
							}}
							description="Recommended size: 400x400px"
							label="Reward Image"
							cta="Upload Image"
							aspectRatio={1 / 1}
							error={errors?.image}
							errorText="Please upload a valid image or video file."
							bannerPosition={
								officialPointsReward.whitelistReward?.imagePosition
							}
							onImageReposition={({ x, y }) => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										imagePosition: { x, y },
									},
								});
							}}
							allowedFiles={['images']}
							maxSize={3}
						/>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default AddOfficialPoints;
