import CampaignCompletionChart from '../components/analytics/participation/CampaignCompletionChart';
import CampaignMetrics from '../components/analytics/participation/CampaignMetrics';
import ChannelsChart from '../components/analytics/users/ChannelsPieChart';
import UserGeoLocationMap from '../components/analytics/users/UserGeoLocationMap';
import TaskCompletionChart from '../components/analytics/tasks/TaskCompletionChart';
import RewardMetrics from '../components/analytics/rewards/RewardMetrics';
import RewardTimeSeries from '../components/analytics/rewards/RewardTimeSeries';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import DetailedTaskAnalysis from '../components/analytics/tasks/DetailedTaskAnalysis';
import Checklist from '../components/checklist/Checklist';
import WaitingForData from '../components/checklist/WaitingForData';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { Status } from '@/features/campaigns/types';

const Analytics = () => {
	const { campaign } = useCampaignReport();
	// const [showDetailedTaskReport, setShowDetailedTaskReport] = useState(false);
	const showChecklist =
		campaign?.initiatedUsersCount === 0 &&
		(campaign.status === Status.Active || campaign.status === Status.Scheduled);
	return (
		<div className="mt-10">
			{showChecklist ? (
				<div className="grid grid-cols-12 gap-5">
					<Checklist />
					<WaitingForData />
				</div>
			) : (
				<>
					<div id="participation" className="">
						<CampaignMetrics />
						<CampaignCompletionChart />
					</div>
					{/* {campaign?.geolocationData?.length > 0 && ( */}
					<div id="users" className="my-20">
						<div className="px-4">
							<h4 className=" text-xl font-medium ">
								🧐 User Demographics and Channels
							</h4>
							<p className="text-sm text-muted-foreground max-w-[500px]">
								Gain insights into where your participants come from
								and how they discovered your quest
							</p>
						</div>
						<div className="grid grid-cols-6 gap-4">
							<div className="col-span-4">
								<UserGeoLocationMap />
							</div>
							<div className="col-span-2">
								{/* <ChannelsChart /> */}
							</div>
						</div>
					</div>
					{/* )} */}
					<div id="tasks" className="my-20">
						<div className="px-4 mb-10 flex justify-between items-center">
							<div>
								<h4 className=" text-xl font-medium ">
									🧐 Task Engagement Analysis
								</h4>
								<p className="text-sm text-muted-foreground max-w-[500px]">
									Dive deep into task-specific interactions,
									completions, and value generated
								</p>
							</div>
							{/* <Button
								variant="outline"
								onClick={() => setShowDetailedTaskReport(true)}
							>
								Show Detailed Task Analysis
							</Button>
							<DetailedTaskAnalysis
								open={showDetailedTaskReport}
								setOpen={setShowDetailedTaskReport}
							/> */}
						</div>
						<div className="grid grid-cols-6 gap-4">
							<div className="col-span-6">
								<TaskCompletionChart />
							</div>
						</div>
					</div>
					{/* <div id="rewards" className="my-20">
						<div className="px-4 mb-10">
							<h4 className=" text-xl font-medium ">
								🧐 Reward Fulfillment Insights
							</h4>
							<p className="text-sm text-muted-foreground max-w-[500px]">
								Understand how rewards are claimed and manage pending
								rewards efficiently
							</p>
						</div>
						<div className="grid grid-cols-6 gap-4 mt-5">
							<div className="col-span-4">
								<RewardTimeSeries />
							</div>
							<div className="col-span-2">
								<RewardMetrics />
							</div>
						</div>
					</div> */}
				</>
			)}
		</div>
	);
};

export default Analytics;
