import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { useEffect, useState } from 'react';
import QuickAccess from '../components/home/QuickAccess';
import SuggestedActions from '../components/home/SuggestedActions';
import GlobalSearch from '../components/search/GlobalSearch';

const DashboardHomePage = () => {
	const { user, refetchUser } = useAuth();
	const [greetings, setGreetings] = useState('');
	const { enterprise } = useGetEnterprise();

	useEffect(() => {
		(async () => {
			if (!user) {
				await refetchUser();
			}
		})();
	}, [user, refetchUser]);

	const changeGreetings = () => {
		const hrs = new Date().getHours();

		if (hrs < 12) {
			setGreetings('☀️ Good morning');
		} else if (hrs >= 12 && hrs < 17) {
			setGreetings('☀️ Good afternoon');
		} else if (hrs >= 17 && hrs < 21) {
			setGreetings('🌙 Good evening');
		} else if (hrs >= 21) {
			setGreetings('🌖 Looks like you are burning midnight oil');
		}
	};

	useEffect(() => {
		changeGreetings();
		setInterval(() => {
			changeGreetings();
		}, 60000);
	}, [user]);

	useEffect(() => {
		if (!enterprise?._id) return;
		analytics.track(TrackingEvents.EnterpriseApprovalStatus, {
			enterpriseId: enterprise?._id,
			isApproved: enterprise?.isApproved,
		});
		if (localStorage.getItem('utmParams')) {
			localStorage.removeItem('utmParams');
		}
	}, [enterprise]);

	return (
		<div>
			<div className="grid grid-cols-12  mt-5 ">
				<div className="col-span-2"></div>
				<div className="col-span-8 space-y-10 mb-20">
					<div className="col-span-4">
						<div className="text-2xl font-medium mb-10">
							{greetings}, {user?.name}
						</div>
					</div>
					<GlobalSearch />
					<SuggestedActions />
					<QuickAccess />
					{/* <Guides /> */}
				</div>
				<div className="col-span-2"></div>
			</div>
		</div>
	);
};

export default DashboardHomePage;
