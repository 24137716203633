import { IGlobalConfigData } from '@/features/getting-started/hooks/useGetGlobalConfigs';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import AddDetails from '../components/create-campaign/details/CampaignDetails';
import CampaignCreateLayout from '../components/create-campaign/layout/CampaignCreateLayout';
import CampaignRewards from '../components/create-campaign/rewards/CampaignRewards';
import EditTasks from '../components/create-campaign/tasks/EditTasks';
import { CreateCampaignProvider } from '../providers/CreateCampaignProvider';
import { CreateCampaignSections } from '../types';

const CampaignCreatePage = () => {
	const [section, setSection] = useState<CreateCampaignSections>(
		CreateCampaignSections.Details,
	);
	const queryClient = useQueryClient();

	useEffect(() => {
		return () => {
			const globalConfigs: IGlobalConfigData = queryClient.getQueryData([
				'global-configs',
			]);
			if (globalConfigs && globalConfigs.questNudges) {
				const shuffledQuestNudges = [...globalConfigs.questNudges].sort(
					() => Math.random() - 0.5,
				);
				queryClient.setQueryData(['global-configs'], {
					...globalConfigs,
					questNudges: shuffledQuestNudges,
				});
			}
		};
	}, [queryClient]);

	return (
		<CreateCampaignProvider section={section} setSection={setSection}>
			<CampaignCreateLayout section={section} setSection={setSection}>
				{section === CreateCampaignSections.Details && <AddDetails />}
				{section === CreateCampaignSections.Tasks && <EditTasks />}
				{section === CreateCampaignSections.Rewards && <CampaignRewards />}
			</CampaignCreateLayout>
		</CreateCampaignProvider>
	);
};

export default CampaignCreatePage;
