import { features } from "process";

export const pohContent = {
  hero: {
    label: "Restoring your Faith in Humanity",
    title: "-ness is the New Legit-ness",
    description:
      "Keep bots + sybils out, know who your real users are, and make the most of their cross-chain and social data for smarter insights",
    features: [
      {
        title: "Feedback boards",
        description: "Collect customer feedback in one easy-to-access place.",
        link: "/features/feedback-boards",
        cta: "Learn more",
      },
      {
        title: "Product Roadmap",
        description:
          "Let your cutsomers know which features are being prioritized first.",
        link: "/features/product-roadmap",
        cta: "Learn more",
      },
      {
        title: "Product Changelog",
        description:
          "Notify your users from small updates to major feature releases.",
        link: "/features/product-changelog",
        cta: "Learn more",
      },
    ],
  },
  features: {
    title: "Powerful analytics for Web3 and the modern marketer",
    description: `There is a reason we are called World’s No. 1 Questing Platform. We've built a suite of powerful features that gives you marketing superpowers. `,
    featureList: [
      {
        emoji: "✨",
        title: "Proof-of-Humanity",
      },
      {
        emoji: "🎉",
        title: "Proof-of-X",
      },
      {
        emoji: "🧩",
        title: "Persona-Based Rewards",
      },
      {
        emoji: "📈",
        title: "User Base Analysis",
      },
    ],
    coreFeatures: [
      {
        id: "1",
        icon: "people",
        title: "Proof-of-Humanity",
        description: `Verify if your users are real humans, not bots to ensure fair distribution of rewards, airdrops, or participation opportunities, reducing fraud and enhancing credibility.`,
        cta: {
          link: "/features",
          label: "Learn more",
        },
        image:
          "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/Proof+of+humanity.svg",
        video: "https://assets.dub.co/features/powerful-analytics.mp4",
      },
      {
        id: "2",
        icon: "people",
        title: "Proof-of-X",
        description: `Verify specific behaviours or attributes (such as being an active gamer, a DeFi participant, or a long-term holder of a token) to tailored user experiences and rewards based on user behaviour.`,
        cta: {
          link: "/features",
          label: "Learn more",
        },
        image:
          "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/Proof-of-X.svg",
        video: "https://assets.dub.co/features/powerful-analytics.mp4",
      },
      {
        id: "3",
        icon: "people",
        title: "Persona-Based Rewards",
        description: `Recognise and reward specific personas (KOLs or loyal users) by assigning point multipliers or benefits based on verified criteria.`,
        cta: {
          link: "/features",
          label: "Learn more",
        },
        image:
          "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/Persona-based+rewards.svg",
        video: "https://assets.dub.co/features/powerful-analytics.mp4",
      },
      {
        id: "4",
        icon: "people",
        title: "User Analysis",
        description: `Make data-driven decisions to enhance user experience and engagement by analysing user behaviours, preferences, and demographics.`,
        cta: {
          link: "/features",
          label: "Learn more",
        },
        image:
          "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/Mint+your+proof+of+humanity.svg",
        video: "https://assets.dub.co/features/powerful-analytics.mp4",
      },
    ],
  },
  coreFeatures: [
    {
      label: "Intract Persona Features",
      title: "F*CK BOTS but how?",
      description:
        "Intract building tech to make sure your users are the real deal without compromising their privacy while you build your project and take care of other important things (like building a community)",
      features: [
        {
          type: "1",
          title: "Decentralised Identity Verification",
          description:
            "Verifiable credentials stored on the blockchain, representing user actions to mitigate risks of centralised IDs like single points of failure and privacy concerns. Select the medallions that you want, have a dedicated page that will leverage Intract Persona. Host it on your website or on Intract.",
          image:
            "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/Decentralised+Identity+Verification_.svg",
        },
        {
          type: "2",
          title: "Customisable Integration",
          description:
            "Identity verification made to fit your needs with medallions that align with your verification criteria without compromising on your UX.",
          image:
            "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/Customisable+Integration_.svg",
        },
        {
          type: "2",
          title: "Cross-Chain Interoperability",
          description:
            "Identity verification across ecosystems, allowing users to prove their identity and behaviours on different chains without starting from scratch. Leverage cross-chain data to verify humanity on your project. Someone with extensive Ethereum history should be able to verify they are human on a new chain like TON or Solana.",
          image:
            "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/Cross-Chain+Interoperability_.svg",
        },
        {
          type: "2",
          title: "API-Driven Integration",
          description:
            "Integrate Intract Persona into your projects easily through APIs for both standard and custom verification processes. Standard and custom APIs designed for your project so that you can use Intract Persona as per your need.",
          image:
            "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/API-Driven+Integration_.svg",
        },
        {
          type: "2",
          title: "Streamlined Data Utilisation",
          description:
            "Integration with various data sources (e.g., Twitter, Discord, Binance, OKX, Coinbase) for comprehensive ID verification without you handling or storing a lot of data. Leverage APIs of platforms like Twitter, Discord, Steam, etc., without worrying about each of them. Just start using Intract Persona and leverage the data without any need to fetch or store them.",
          image:
            "https://s3.ap-south-1.amazonaws.com/static.highongrowth.xyz/images/POH/Streamlined+data+util.svg",
        },
      ],
    },
  ],
  dataSources: {
    title: "Integrations and Data Sources",
    logos: [
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/41cb0a7cb92443f78af593b5841ff143.svg",
        name: "azuki",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/2995404cdd784da58ecd8550d3d8f285.svg",
        name: "BAYC",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/cc9f6040374c445fa4a2876adacc08b3.svg",
        name: "Crypto Punk",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/57cf4024d3c546e19de3128e0723d0f8.svg",
        name: "Doodle NFT",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/eec5bd0f5b02409f8b963d516c65c04f.svg",
        name: "Pudgy Penguin",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/71607b9ad3d1466ebf9d2764840237a4.svg",
        name: "arbitrum",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/6ef9b013ad4342139f476e7b4a70845c.svg",
        name: "layer zero",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/4ac35c6a2e304d75a56b18c2ec2ee1ea.svg",
        name: "LXP",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/d02eb5ee42214368a31fc541f3e559e5.svg",
        name: "Optimism",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/7a272bf07b4c495b919a7643d917bde7.svg",
        name: "Uniswap",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/c69bbbc2762c445bb0e8dd0d139818a7.svg",
        name: "Zksync",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/61527c9308d74b54aaf3cdb8ebcd6a48.svg",
        name: "BABT",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/207eecb10726471b8f2a0d7908a6edda.svg",
        name: "World Coin",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/8babc01a89d94dc6be813200e9494f7d.svg",
        name: "Zkpass",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/66596dd621949f76bb468348/dd27c81e250b437e838e5801dbd80510.webp",
        name: "reclaim",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/c6c20a1775874d548905550673ef6fa8.svg",
        name: "discord",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/5445bbbee0464a9982e31f0950074e03.svg",
        name: "gmail",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/b2530e4b54324c8c996a4b36e0024449.svg",
        name: "google captcha",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/c1cc38b6f3cb4d98b7f78d3fb82c1f8a.svg",
        name: "google icon",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/f86314f0919c48bf89dfb33c8956d7b8.svg",
        name: "intract",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/fd7064f3f4b94a5898053e0684784934.svg",
        name: "twitter",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/443b04d64bcf406990bcdcf8631899fb.svg",
        name: "github",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/ddd2ce44cc86426590665e4cd6616412.svg",
        name: "steam",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/64eccafe77b24411935dc85365c2be0b.svg",
        name: "Sui",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/66596dd621949f76bb468348/d5d756a4fb8d44939ccb042fbd8f7b47.svg",
        name: "Solana",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/86f240d173d94d4ca9d73d7247d3e0e7.svg",
        name: "Farcaster",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/66596dd621949f76bb468348/6bf77987e9c1412e90c6c8be3378134b.svg",
        name: "Cosmos",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/66596dd621949f76bb468348/3c7eafdfbc7941e3a3bd9d2291955802.svg",
        name: "Ethereum",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/66596dd621949f76bb468348/cf5377786b444623ac87c3145893961f.svg",
        name: "Bitcoin",
      },
      {
        img: "https://static.highongrowth.xyz/enterprise/66596dd621949f76bb468348/1aa9cee7752a4ae7b9ef7d9998074f0a.svg",
        name: "Ton",
      },
    ],
  },
};
